import React, { useContext, useState } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import Context from "../../Context/Context";
import { toast } from "react-toastify";
import RazorpayIcon from "../../Utils/Assests/razorpay.svg";
import WarningModal from "./WarningModal";

const RazorpayPayment = ({ productId, planId, heading }) => {
  const { userData } = useContext(Context);
  const Navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const RAZORPAY_KEY_ID =
    process.env.REACT_APP_STAGE === "PROD"
      ? process.env.REACT_APP_RAZORPAY_LIVE_KEY_ID
      : process.env.REACT_APP_RAZORPAY_TEST_KEY_ID;

  const handleSubscribe = async () => {
    // Check if user already has an active subscription
    if (userData.subscriptionId) {
      setShowModal(true);
      return;
    }

    setIsLoading(true);

    try {
      // Initiate payment - first API call to create subscription
      const response = await API.put("user", `/user/payment-initiate`, {
        body: {
          institutionId: userData.institution,
          cognitoId: userData.cognitoId,
          productId: productId,
          planId: planId,
        },
      });

      // Extract subscription details
      const subscriptionResult = response;
      const subscriptionId = subscriptionResult.paymentId;

      // Razorpay checkout options
      const options = {
        key: RAZORPAY_KEY_ID,
        amount: subscriptionResult.amount * 100, // Convert to paisa
        currency: subscriptionResult.currency,
        name: userData.institution.toUpperCase(),
        subscription_id: subscriptionId,
        description: subscriptionResult.subscriptionType,
        handler: async function (paymentResponse) {
          try {
            // Verify payment - second API call
            const verifyResponse = await API.put(
              "user",
              `/user/payment-verify`,
              {
                body: {
                  institutionId: userData.institution,
                  cognitoId: userData.cognitoId,
                  subscriptionId: subscriptionId,
                  productId: productId,
                  subscriptionType:heading,
                  razorpay_payment_id: paymentResponse.razorpay_payment_id,
                },
              }
            );

            // Handle successful payment
            if (verifyResponse.signatureIsValid) {
              toast.success("Subscription activated successfully!");
              Navigate("/");
              setTimeout(() => {
                window.location.reload();
              }, 100);
            } else {
              throw new Error("Payment verification failed");
            }
          } catch (error) {
            console.error("Payment verification error:", error);
            toast.error("Payment verification failed. Please try again.");
          } finally {
            setIsLoading(false);
          }
        },
        prefill: {
          email: userData.emailId || "",
          contact: userData.phoneNumber || "",
        },
        notes: {
          cognitoId: userData.cognitoId,
          productId: productId,
          planId: planId,
        },
        theme: {
          color: "#205b8f",
        },
        modal: {
          ondismiss: async function () {
            try {
              // Cancel subscription if payment modal is dismissed
              await API.del("awsaiapp", `/cancel/payment`, {
                body: {
                  cognitoId: userData.cognitoId,
                  subscriptionId: subscriptionId,
                },
              });
            } catch (error) {
              console.error("Error cancelling payment:", error);
            } finally {
              setIsLoading(false);
            }
          },
        },
      };

      // Open Razorpay checkout
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error("Error during checkout:", error);
      toast.error("Error initiating subscription. Please try again.", {
        position: "top-right",
      });
      setIsLoading(false);
    }
  };

  return (
    <div className="z-1">
      <button
        className={`w-[18rem] text-white px-12 py-2 rounded-2xl hover:text-[#FDCF08] hover:bg-white hover:border-lightPrimaryColor hover:border-[0.2rem] h-[3rem] flex justify-center items-center mt-16 max450:w-[60vw] cursor-pointer ${
          isLoading ? "opacity-50 cursor-not-allowed" : ""
        }`}
        style={{
          backgroundColor: "#FDCF08",
        }}
        onClick={handleSubscribe}
        disabled={isLoading}
      >
        <img src={RazorpayIcon} alt="RazorpayIcon" style={{ width: "8rem" }} />
        <p className="text-gray-800 font-extralight text-[1rem] mb-0 mt-2 ml-1">
          {isLoading ? "Processing..." : "Subscribe"}
        </p>
      </button>
      <p className="text-sm flex gap-1 justify-center mt-2">
        Powered by{" "}
        <img className="w-20" src={RazorpayIcon} alt="RazorpayIcon2" />
      </p>

      <WarningModal isOpen={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};

export default RazorpayPayment;